import {
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Stack,
  Typography,
} from '@mui/material';
import { FC, useCallback, useContext, useMemo, useState } from 'react';
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
import { getHomepageUrl, validateEmail, validatePassword } from 'common/utils';
import { useLocation, useNavigate } from 'react-router-dom';

import ClientAPI from 'common/ClientAPI';
import { TrackingContext } from 'contexts/TrackingContext';
import { Uris } from 'Uris';
import classes from './SignInUpPage.module.scss';
import { colorPrimary10 } from 'common/params';
import { useTracking } from 'common/hooks/useTracking';

interface SignInUpPageProps {
  signIn: boolean;
}

export const SignInUpPage: FC<SignInUpPageProps> = ({ signIn }) => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { track } = useTracking();
  const { entryPoint$ } = useContext(TrackingContext);

  const [showPassword, setShowPassword] = useState(false);
  const [email, setEmail] = useState<string>(state?.email?.toLowerCase() || '');
  const [password, setPassword] = useState<string>('');

  const [emailError, setEmailError] = useState<boolean>(false);
  const [passworError, setPasswordError] = useState<boolean>(false);
  const [backendError, setBackendError] = useState<string>('');

  const onShowPasswordBtnClicked = () => setShowPassword((show) => !show);

  const signInWithEmail = useCallback(
    async (email: string, password: string) => {
      track('click', {
        sub_event: 'login_attempted',
        custom_props: { entry_point: entryPoint$.getValue(), authentication_method: 'Email' },
      });
      const emailError = !validateEmail(email);
      const passwordError = !validatePassword(password);
      setEmailError(emailError);
      setPasswordError(passwordError);
      if (emailError || passwordError) return;

      await ClientAPI.signIn(email, password)
        .then(async ({ status, data }) => {
          if (status === 'success') {
            // has redirect url -> directly change
            await track(
              'click',
              {
                sub_event: 'login_completed',
                custom_props: { entry_point: entryPoint$.getValue(), authentication_method: 'Email' },
              },
              data?.access_token,
            ).finally(() => {
              if (data?.redirect_url) {
                data.redirect_url && window.location.replace(data.redirect_url);
                return;
              }
              // redirect to homepage
              window.location.replace(getHomepageUrl(Uris.External.Home));
            });
          } else if (status === 'redirect' && data?.account_id) {
            // redirect to register confirm page
            track('click', {
              sub_event: 'login_to_signup',
              custom_props: { entry_point: entryPoint$.getValue(), authentication_method: 'Email' },
            });
            navigate(Uris.Pages.Auth.RegisterConfirm.replace(':accountId', data?.account_id), {
              state: { email, authentication_method: 'Email' },
            });
          } else {
            track('click', {
              sub_event: 'login_failed',
              custom_props: { entry_point: entryPoint$.getValue(), authentication_method: 'Email' },
            });
            setBackendError(`Sign in failed`);
          }
        })
        .catch((error) => {
          track('click', {
            sub_event: 'login_failed',
            custom_props: { entry_point: entryPoint$.getValue(), authentication_method: 'Email' },
          });
          setBackendError(error instanceof Error ? error.message : 'Unknow Error');
        });
    },
    [navigate, track, entryPoint$],
  );

  const signUpWithEmail = useCallback(
    async (email: string, password: string) => {
      track('click', {
        sub_event: 'register_attempted',
        custom_props: {
          entry_point: entryPoint$.getValue(),
          authentication_method: 'Email',
        },
      });
      const emailError = !validateEmail(email);
      const passwordError = !validatePassword(password);
      setEmailError(emailError);
      setPasswordError(passwordError);
      if (emailError || passwordError) return;

      await ClientAPI.signUp(email, password)
        .then(({ data }) => {
          if (data?.account_id) {
            navigate(`${Uris.Pages.Auth.RegisterConfirm.replace(':accountId', data?.account_id)}`, {
              state: { email, authentication_method: 'Email' },
            });
            return;
          }
          setBackendError(`Sign up failed`);
        })
        .catch((error) => {
          setBackendError(error instanceof Error ? error.message : 'Unknow Error');
        });
    },
    [navigate, track, entryPoint$],
  );

  const emailFieldDisabled = useMemo(() => {
    return !!state?.email;
  }, [state]);

  return (
    <Stack direction='row' className={classes.root}>
      <Stack className={classes.imgContainer}>
        <img alt='login' src={Uris.Public.Image.Auth.Promo} className={classes.img} />
      </Stack>
      <Stack className={classes.block}>
        <Stack className={classes.container} spacing={3}>
          <Stack direction='row' justifyContent='center'>
            <img alt='logo' className={classes.logo} src={Uris.Public.Logo.NoSub.Dark} />
          </Stack>
          <Typography variant='h4' color={colorPrimary10}>
            {signIn ? 'Sign In' : 'Sign Up'}
          </Typography>
          <Stack spacing={2}>
            <FormControl>
              <InputLabel error={!!emailError}>Your email address</InputLabel>
              <OutlinedInput
                size='medium'
                label={'Your email address'}
                disabled={emailFieldDisabled}
                error={!!emailError}
                value={email}
                onChange={(e) => setEmail(e.target.value.toLowerCase())}
              />
              {emailError ? (
                <Stack style={{ maxWidth: '360px' }}>
                  <FormHelperText error>Invalid email format</FormHelperText>
                </Stack>
              ) : null}
            </FormControl>
            <FormControl>
              <InputLabel error={!!passworError}>Your password</InputLabel>
              <OutlinedInput
                size='medium'
                label={'Your password'}
                error={!!passworError}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password visibility'
                      onClick={onShowPasswordBtnClicked}
                      onMouseDown={onShowPasswordBtnClicked}
                      edge='end'
                    >
                      {showPassword ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {passworError ? (
                <Stack>
                  <FormHelperText error={true}>
                    Minimum length is 6 characters. Contains 1 character and 1 digit.
                  </FormHelperText>
                </Stack>
              ) : null}
            </FormControl>
          </Stack>
          {backendError ? <Typography color='error'>{backendError}</Typography> : null}
          <Stack direction='row-reverse' alignItems='center' justifyContent='space-between'>
            <Stack direction='row' spacing={1}>
              {emailFieldDisabled ? (
                <Button
                  variant='outlined'
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Back
                </Button>
              ) : null}
              <Button
                variant='contained'
                onClick={() => {
                  if (signIn) signInWithEmail(email, password);
                  else signUpWithEmail(email, password);
                }}
              >
                Continue
              </Button>
            </Stack>
            {signIn ? (
              <Link
                variant='body1'
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  track('click', {
                    sub_event: 'password_reset_started',
                    custom_props: {
                      entry_point: entryPoint$.getValue(),
                      email: email,
                    },
                  });
                  navigate(Uris.Pages.Auth.ForgetPassword, {
                    state: { email },
                  });
                }}
              >
                Forget Password
              </Link>
            ) : null}
          </Stack>
          <Typography variant='body2'>
            By clicking “Continue” you agree to our{' '}
            <Link variant='body2' href={getHomepageUrl(Uris.External.TermsOfService)} target='_blank'>
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link variant='body2' href={getHomepageUrl(Uris.External.PrivacyPolicy)} target='_blank'>
              Privacy Policy
            </Link>
            .
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
